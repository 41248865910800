import './App.css';
function App() {
  return (
    <div className="App">
          <span style={{color: 'white'}}>Subscribe Zainkeepscode</span>
    </div>
  );
}

export default App;
